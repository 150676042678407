import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { sendNewsLetterEmail } from "utils/apiUtil";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";
import { ContactFun } from "api/profile/profile";
import { Link } from "react-router-dom";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";

export interface PageContactProps {
  className?: string;
}

const contacts = [
  {
    id: 1,
    icon: (
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="64" rx="8" fill="#013D79"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.54 25C26.6 25.89 26.75 26.76 26.99 27.59L25.79 28.79C25.38 27.59 25.12 26.32 25.03 25H26.54ZM36.4 37.02C37.25 37.26 38.12 37.41 39 37.47V38.96C37.68 38.87 36.41 38.61 35.2 38.21L36.4 37.02ZM27.5 23H24C23.45 23 23 23.45 23 24C23 33.39 30.61 41 40 41C40.55 41 41 40.55 41 40V36.51C41 35.96 40.55 35.51 40 35.51C38.76 35.51 37.55 35.31 36.43 34.94C36.33 34.9 36.22 34.89 36.12 34.89C35.86 34.89 35.61 34.99 35.41 35.18L33.21 37.38C30.38 35.93 28.06 33.62 26.62 30.79L28.82 28.59C29.1 28.31 29.18 27.92 29.07 27.57C28.7 26.45 28.5 25.25 28.5 24C28.5 23.45 28.05 23 27.5 23Z" fill="#089444"/>
</svg>

    ),
    title: "Phone Number",
    detail: globalJson.phone,
  },
  {
    id: 2,
    icon: (
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="8" fill="#013D79"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42 26C42 24.9 41.1 24 40 24H24C22.9 24 22 24.9 22 26V38C22 39.1 22.9 40 24 40H40C41.1 40 42 39.1 42 38V26ZM40 26L32 31L24 26H40ZM40 38H24V28L32 33L40 28V38Z" fill="#089444"/>
      </svg>
      
    ),
    title: "Email",
    detail: globalJson.email,
  },
  {
    id: 3,
    icon: (
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="8" fill="#013D79"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32 22C35.87 22 39 25.13 39 29C39 34.25 32 42 32 42C32 42 25 34.25 25 29C25 25.13 28.13 22 32 22ZM27 29C27 31.85 29.92 36.21 32 38.88C34.12 36.19 37 31.88 37 29C37 26.24 34.76 24 32 24C29.24 24 27 26.24 27 29ZM32 31.5C30.6193 31.5 29.5 30.3807 29.5 29C29.5 27.6193 30.6193 26.5 32 26.5C33.3807 26.5 34.5 27.6193 34.5 29C34.5 30.3807 33.3807 31.5 32 31.5Z" fill="#089444"/>
      </svg>
      
    ),
    title: "Map Street",
    detail: globalJson.address,
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length === 0 || newName.length === 0 || message.length === 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }

    // Create the payload with name, email, and message
    const payload = {
      name: newName,
      email: getEmail,
      message: message,
    };

    const result = await ContactFun(payload);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sent successfully!");
      setEmail("");
      setNewName("");
      setMessage("");
    }

    setLoading(false);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="mb-24 lg:mb-32 mt-24">
       
        <div className="container mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-6 lg:gap-12">
            <div className="mt-8 max-w-sm">
              <h2 className="text-4xl font-semibold">Get in Touch</h2>
              <p className="my-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod.
              </p>
              {/* <div className="flex space-x-2">
                <Link to="https://www.facebook.com" className="cursor-pointer">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="39"
                      height="39"
                      rx="19.5"
                      stroke="#07BEB8"
                    />
                    <path
                      d="M24.3525 21.375H21.7158V29.25H18.2002V21.375H15.3174V18.1406H18.2002V15.6445C18.2002 12.832 19.8877 11.25 22.4541 11.25C23.6846 11.25 24.9854 11.4961 24.9854 11.4961V14.2734H23.5439C22.1377 14.2734 21.7158 15.1172 21.7158 16.0312V18.1406H24.8447L24.3525 21.375Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link to="https://www.instagram.com" className="cursor-pointer">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="39"
                      height="39"
                      rx="19.5"
                      stroke="#07BEB8"
                    />
                    <path
                      d="M15.8377 27H12.5682V16.4883H15.8377V27ZM14.1854 15.082C13.1658 15.082 12.3221 14.2031 12.3221 13.1484C12.3221 12.1289 13.1658 11.2852 14.1854 11.2852C15.24 11.2852 16.0838 12.1289 16.0838 13.1484C16.0838 14.2031 15.24 15.082 14.1854 15.082ZM28.0369 27H24.8025V21.9023C24.8025 20.6719 24.7674 19.125 23.0799 19.125C21.3924 19.125 21.1463 20.4258 21.1463 21.7969V27H17.8768V16.4883H21.0057V17.9297H21.0408C21.4979 17.1211 22.5525 16.2422 24.1346 16.2422C27.4393 16.2422 28.0721 18.4219 28.0721 21.2344V27H28.0369Z"
                      fill="black"
                    />
                  </svg>
                </Link>
              </div> */}
              <div className="flex flex-col mt-6">
                <div className=" space-y-6">
                  {contacts.map((contact) => (
                    <div
                      key={contact.id}
                      className="flex items-center space-x-4"
                    >
                      {/* Icon */}
                      <div className="flex items-center justify-center w-12 h-12 ">
                        {contact.icon}
                      </div>

                      {/* Text */}
                      <div>
                        <p className="text-gray-500 text-sm">{contact.title}</p>
                        <p className="text-gray-900 text-lg font-semibold">
                          {contact.detail}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div >
              <div className="py-4">
               
              </div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={getEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" className="min-w-[200px]">
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#013D79]">
          <div className="container relative py-16">
            <RentalPolicies />
          </div>
        </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
