import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import FleetCard from "components/FleetCard/FleetCard";
import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import { CarListingModal } from "models/vehicleModal";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const { ourVehicles, setSelectedCar } = useData();

  // Use useState for maxPrice and set it later
  const [maxPrice, setMaxPrice] = useState(0);
  const [filteredFleet, setFilteredFleet] = useState<CarListingModal[]>(
    ourVehicles || []
  );
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  // Calculate maxPrice when ourVehicles is updated
  useEffect(() => {
    if (ourVehicles && ourVehicles.length > 0) {
      const calculatedMaxPrice = ourVehicles.reduce(
        (acc, current) => Math.max(acc, current.price),
        0
      );
      setMaxPrice(calculatedMaxPrice);
    }
  }, [ourVehicles]);

  // Set initial rangePrices based on maxPrice
  useEffect(() => {
    if (maxPrice > 0) {
      setRangePrices([0, maxPrice]);
    }
  }, [maxPrice]);

  // Filter vehicles by selected types
  useEffect(() => {
    if (ourVehicles) {
      if (
        selectedTypes.length === 0 ||
        Array.from(new Set(ourVehicles.map((car) => car?.fleet_class))).filter(
          (item): item is any => item !== undefined
        ).length === selectedTypes.length
      ) {
        setFilteredFleet(ourVehicles);
      } else {
        setFilteredFleet(
          ourVehicles.filter((item: CarListingModal) =>
            selectedTypes.includes(item?.fleet_class?.name ?? "")
          )
        );
      }
    }
  }, [selectedTypes, ourVehicles]);

  // Filter vehicles by price range
  useEffect(() => {
    if (ourVehicles) {
      setFilteredFleet(
        ourVehicles.filter(
          (item) => item.price >= rangePrices[0] && item.price <= rangePrices[1]
        )
      );
    }
  }, [rangePrices, ourVehicles]);

  const renderCard = (car: CarListingModal) => {
    return (
      <FleetCard
        key={car.id}
        data={car}
        simpleImg={true}
        onClick={() => {
          navigate("/car-listing-detail");
          setSelectedCar(car);
        }}
      />
    );
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{ minHeight: "80vh" }}
    >
      <div className="flex flex-col sm:flex-row justify-between align-center">
        <span className="lg:text-5xl md:text-3xl text-3xl font-semibold lg:leading-[73.14px] leading-12 text-left mb-4 text-[#3B3B3B]">
          <span className="text-primary-200">Explore</span> All Vehicles
        </span>
        <div className="mb-8 lg:mb-0 flex items-center">
          {ourVehicles && (
            <TabFilters
              types={[
                ...Array.from(
                  new Set(
                    ourVehicles.map((car) => car?.fleet_class?.name ?? "")
                  )
                ).filter(
                  (item): item is string => item !== undefined && item !== ""
                ),
              ]}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              rangePrices={rangePrices}
              setRangePrices={setRangePrices}
              maxPrice={maxPrice}
            />
          )}
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.map((car) => renderCard(car))}
        </div>
        <div className="flex mt-16 justify-center items-center">
          {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
